/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState } from "react";
import { auth, useAuth } from "gatsby-theme-firebase";
import { Link, StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Button from "gatsby-theme-firebase/src/components/Button";
import LoginModal from "../components/LoginModal";
import Session from "../components/Session"
import Colors from "../components/colors"
import CleanAndMapSessions from "../utils/CleanAndMapSessions"

const CompaniesPage = () => {
  const [toggleLogin, setToggleLogin] = useState(false);
  const { isLoading, isLoggedIn, profile } = useAuth();

  if (isLoading) {
    return <h1>Loading...</h1>;
  }
  return(
    <StaticQuery query={graphql`
      query SessionQuery
       {
        allAirtable(filter: {table: {eq: "Engagements"}, data: {Date: {lte: "NOW()"}}}, sort: {fields: data___Date, order:DESC}) {
          edges {
            node {
              data {
                Id
                Title
                Date
                Type
                Company_Name
                Member_Name
                Notes {
                  internal {
                    content
                  }
                }
                Summary
                api_engagements_minutes
                Event
                Attended_
                speaker_name
                team_member_name
              }
            }
          }
        }
      }
    `}
      render={data => (
        <Layout resetPadding>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            padding:'2rem 3rem',
            height: '100%',
            width: '100%',
            backgroundColor: Colors.gray[0]
          }}>
          {
            isLoggedIn && profile.emailVerified ? (
              <div style={{maxWidth: 1000, width: '100%'}}>
                {
                  data &&
                  CleanAndMapSessions(data.allAirtable.edges.map((edge) => edge.node.data)).map((session) => (
                    <Session key={session.Id} session={session} hideDate={false} condensed={false} showCompany={true}/>
                  ))
                }
              </div>
            )
            : (
              <div>
                <h1>You must be logged in to see this page.</h1>
                <Button
                  onClick={() => {
                    setToggleLogin(true);
                  }}
                >
                  Login
                </Button>
              </div>
            )
          }
          {toggleLogin && <LoginModal setToggleLogin={setToggleLogin} />}
          </div>
        </Layout>
      )}
    />
  )
  
}

export default CompaniesPage
